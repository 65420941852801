import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { bt_darkblue, bt_lightblue, bt_orange } from "../components/color"
import { BTButton } from "../components/common"
import Card from "../components/card"
import Logo from "../components/logo"
import SplashImage from "../images/protect-ourselves.svg"
import Whitepaper from "../documents/bluetrace_whitepaper.pdf"

import Layout, { Spacer } from "../components/layout"
import SEO from "../components/seo"

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`
const TitleText = styled.div`
  margin: 4vh 0vh;
  h1 {
    margin: 0px;
    font-family: Barlow;
    font-style: normal;
    font-weight: bold;
    font-size: 2em;
    color: ${bt_lightblue};
  }
  h1:nth-child(3) {
    color: ${bt_darkblue};
  }
`
const ButtonSection = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`
const LogoContainer = styled.div`
  margin-bottom: 1.5vh;
  height: calc(15vh);
  width: 100%;
`
const SplashContainer = styled.div`
  padding: 10vh 2vh;
  @media (max-width: 991.98px) {
    padding-top: 0vh;
  }
`
const SplashImageContainer = styled.img`
  width: 100%;
  @media (max-width: 991.98px) {
    margin-top: 10vh;
  }
`
const BlueBGShape = styled.svg`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  path {
    fill: ${bt_darkblue};
  }
`
const MainBody = styled.div`
  position: relative;
`
const MainBodyContent = styled.div`
  padding: 20vh 0vw;
  section {
    margin-bottom: 10vh;
    max-width: 50vw;
    @media (max-width: 991.98px) {
      max-width: 100%;
    }
  }
  section {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    color: white;
  }
  h1 {
    font-weight: bold;
  }
  a {
    font-weight: 700;
    line-height: 22px;
    height: 40px;
    padding: 3px;
    color: white;
    text-decoration: underline;
  }
  a:active {
    filter: brightness(150%);
    color: ${bt_orange};
  }
  a:hover {
    color: ${bt_orange};
  }
`

const card_info = [
  {
    title:
      "Third-parties cannot use BlueTrace communications to track users over time",
    blurb: `A device’s
      temporary identifier rotates frequently, preventing
      malicious actors from tracking individual
      users over time by sniffing for BlueTrace messages.`,
  },
  {
    title: "Limited collection of personally-identifiable information",
    blurb: `The only personally-identifiable
      information collected is a phone number, which
      is securely stored by the health authority.`,
  },
  {
    title: "Local storage of encounter history",
    blurb: `Each user’s
      encounter history is stored exclusively on their
      own device. The health authority only has access
      to this history when an infected person chooses
      to share it.`,
  },
  {
    title: "Revocable consent",
    blurb: `Users have control of their
      personal data. When they withdraw consent, all
      personally-identifiable data stored at the health
      authority is deleted. All encounter history will
      thus cease to be linked to the user.`,
  },
]

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      {/* Splash Section */}
      <div className="container">
        <SplashContainer className="row">
          <TextSection className="col-md-6 col-xs-12">
            <LogoContainer>
              <Logo />
            </LogoContainer>
            <TitleText>
              <h1 color={bt_lightblue}>Privacy-Preserving</h1>
              <h1 color={bt_lightblue}>Cross-Border</h1>
              <h1 color={bt_darkblue}>Contact Tracing</h1>
            </TitleText>
            <ButtonSection>
              <BTButton
                className="btn"
                bgColor={bt_lightblue}
                bgActiveColor={bt_orange}
                color="#fff"
                borderColor={bt_lightblue}
                to="/policy/index.html"
                isInternalLink
              >
                Read Policy Brief
              </BTButton>
              <BTButton
                className="btn"
                bgColor="transparent"
                bgActiveColor={bt_orange}
                color={bt_darkblue}
                borderColor={bt_darkblue}
                to={Whitepaper}
                target="_blank"
              >
                Read White Paper
              </BTButton>
            </ButtonSection>
          </TextSection>
          <div className="col-md-6 col-xs-12">
            <SplashImageContainer
              src={SplashImage}
              alt="BlueTrace used in a crowd"
            />
          </div>
        </SplashContainer>
      </div>
      {/* Main Content Section */}
      <MainBody>
        <BlueBGShape
          viewBox="0 0 1440 1409"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <path d="M0 59.8904L1440 0V1409L0 1349L0 59.8904Z" />
        </BlueBGShape>
        <div className="container">
          <MainBodyContent>
            <section>
              <h1>What is BlueTrace?</h1>
              <p>
                Contact tracing is an important tool to reduce the spread of
                infectious diseases like COVID-19. BlueTrace is a
                privacy-preserving protocol for community-driven contact tracing
                using Bluetooth devices, that allows for global
                inter-operability.
                <br />
                <br />
                BlueTrace is designed for decentralised proximity logging and
                supplements centralised contact tracing by public health
                authorities. Proximity logging using Bluetooth addresses a key
                limitation of manual contact tracing: that it is dependent on a
                person’s memory and is therefore limited to contacts that a
                person is acquainted with and remembers having met. BlueTrace
                therefore enables contact tracing to be more scalable and less
                resource-intensive.
              </p>
              <p>
                <a href={Whitepaper} target="_blank" rel="noopener noreferrer">
                  Find out more about BlueTrace by reading the white paper
                </a>
              </p>
              <p>
                <Link to="/manifesto/index.html">Read the BlueTrace manifesto</Link>
              </p>
            </section>
            <section>
              <h1>BlueTrace is designed around privacy</h1>
              {card_info.map((info, index) => (
                <Card
                  key={`card-${index}`}
                  index={index}
                  CardInfo={{ title: info.title, blurb: info.blurb }}
                />
              ))}
              <p>
                BlueTrace protocol can be implemented using the{" "}
                <a href="https://github.com/opentrace-community">
                  OpenTrace code
                </a>
                . <a href="https://www.tracetogether.gov.sg">TraceTogether</a>{" "}
                was launched in Singapore on 20 March 2020. It is the first
                national Bluetooth contact tracing app in the world, and uses
                the OpenTrace code to implement the BlueTrace protocol.
              </p>
            </section>
          </MainBodyContent>
        </div>
      </MainBody>
      <Spacer height="10vh" />
      {/* Coalition information */}
    </Layout>
  )
}

export default IndexPage
