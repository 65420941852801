import styled from "styled-components"
import { Link } from "gatsby"
import React from "react"

export const BTButton = styled(styledBtn)`
  && {
    display: inline-block;
    font-family: Barlow, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    height: 40px;
    padding: 8px;
    background-color: ${props => props.bgColor};
    color: ${props => props.color};
    border-color: ${props => props.borderColor};
    border-width: 1px;
    border-radius: 5px;
    margin: 5px;
  }
  &&:active {
    background-color: ${props => props.bgActiveColor};
  }
  &&:hover {
    filter: brightness(120%);
    color: ${props => props.color};
  }
`

function styledBtn(props) {
  if (props.isInternalLink) {
    return (
      <Link to={props.to} className={props.className}>
        {props.children}
      </Link>
    )
  }
  return (
    <a
      href={props.to}
      className={props.className}
      target={props.target}
      rel={props.target === "_blank" ? "noopener noreferrer" : ""}
      download={props.download}
    >
      {props.children}
    </a>
  )
}
