import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  border: 2px solid #ffffff;
  border-radius: 5px;
  box-sizing:border-box;
  display:flex;
  padding: 2vh 0vw;
  margin: 3vh 0vw;
  align-items:center;
  width: 100%;
`
const TextContainer = styled.div`
  flex:9;
  margin: 0vh 2vw;
  h1,p{
    font-size:1em;
  }
  h1{
    margin-bottom:3vh;
  }
  p{
    margin-bottom:0px;
  }
`
const CardNumber = styled.h1`
  flex:1;
  display:flex;
  align-items:center;
  justify-content:center;
  @media (max-width: 575.98px){
    margin: 0vh 3vw;
  }
  margin: 0vh 1vw;
`
export default function Card(props){
  return(
    <CardContainer>
      <CardNumber>#{props.index+1}</CardNumber>
      <TextContainer>
        <h1>{props.CardInfo.title}</h1>  
        <p>{props.CardInfo.blurb}</p>  
      </TextContainer>
    </CardContainer>
  )
}
